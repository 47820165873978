export default {
  ATHLETES: {
    name: 'Athletes',
    path: '/athletes'
  },
  ATHLETE_DETAILS: {
    name: 'AthleteDetailsLanding',
    path: ':athleteId'
  },
  ATHLETE_DETAILS_STATS: {
    name: 'AthleteDetailsStats',
    path: ':athleteId/stats'
  },
  ATHLETE_DETAILS_EVENTS: {
    name: 'AthleteDetailsEvents',
    path: ':athleteId/events'
  },
  ATHLETE_DETAILS_GAMES: {
    name: 'AthleteDetailsGames',
    path: ':athleteId/games'
  },
  ATHLETE_DETAILS_AWARDS: {
    name: 'AthleteDetailsAwards',
    path: ':athleteId/awards'
  },
  // AGE_CALCULATOR: {
  //   name: 'AthleteAgeCalculator',
  //   path: '/athletes/ageCalculator'
  // },
  BASEBALL_LANDING: {
    name: 'BaseballLanding',
    path: '/baseball/landing'
  },
  BASEBALL_FAQs: {
    name: 'BaseballFAQs',
    path: '/baseball/faq'
  },
  BASEBALL_STATE_DIRECTORS: {
    name: 'BaseballStateDirectors',
    path: '/baseball/state-directors'
  },
  BASEBALL_FIND_DIRECTOR: {
    name: 'BaseballFindDirector',
    path: '/baseball/find-director'
  },
  BASEBALL_FIND_VENUE: {
    name: 'BaseballFindVenue',
    path: '/baseball/find-venue'
  },
  BASKETBALL_LANDING: {
    name: 'BasketballLanding',
    path: '/basketball/landing'
  },
  BRACKETS: {
    name: 'BracketLanding',
    path: '/brackets'
  },
  CHALLENGE_CUP: {
    name: 'ChallengeCup',
    path: 'slowpitch/slowpitchPrograms/challenge-cup'
  },
  CONTACT: {
    name: 'Contact',
    path: 'contact'
  },
  EQUIPMENT: {
    name: 'BatsBallsEquipment',
    path: 'bats-balls-and-equipment'
  },
  ELLITE_WORLD_SERIES: {
    name: 'ElliteWorldSeries',
    path: 'baseball/baseballPrograms/baseball-elite-ws'
  },
  EVENTS: {
    name: 'PublicEvents',
    path: '/events'
  },
  EVENT_DETAILS: {
    name: 'EventDetailsLanding',
    path: ':eventId'
  },
  EVENT_DETAILS_GAME_CENTER: {
    name: 'EventDetailsGameCenter',
    path: ':eventId/gameCenter'
  },
  EVENT_DETAILS_WHOS_COMING: {
    name: 'EventDetailsWhosComing',
    path: ':eventId/whosComing'
  },
  EVENT_DETAILS_VENUES: {
    name: 'EventDetailsVenues',
    path: ':eventId/venues'
  },
  EVENT_DETAILS_MERCHANDISE: {
    name: 'EventDetailsMerchandise',
    path: ':eventId/merchandise'
  },
  EVENT_DIVISIONS: {
    name: 'EventDivisionsLanding',
    path: 'divisions/:divisionId'
  },
  FASTPITCH_LANDING: {
    name: 'FastpitchLanding',
    path: '/fastpitch/landing'
  },
  FASTPITCH_FAQs: {
    name: 'FastpitchFAQs',
    path: '/fastpitch/faq'
  },
  FASTPITCH_STATE_DIRECTORS: {
    name: 'FastpitchStateDirectors',
    path: '/fastpitch/state-directors'
  },
  FASTPITCH_FIND_DIRECTOR: {
    name: 'FastpitchFindDirector',
    path: '/fastpitch/find-director'
  },
  FASTPITCH_FIND_VENUE: {
    name: 'FastpitchFindVenue',
    path: '/fastpitch/find-venue'
  },
  FAQs: {
    name: 'FrequentlyAskedQuestions',
    path: 'faqs'
  },
  FORGOT_PASSWORD: {
    name: 'ForgotPassword',
    path: 'forgotPassword'
  },
  HALL_OF_FAME: {
    name: 'HallOfFame',
    path: '/hqNationalOffice/usssa-hall-of-fame'
  },
  LOGIN: {
    name: 'Login',
    path: 'login'
  },
  LEGAL_ABUSE: {
    name: 'AbuseAndMolestation',
    path: '/legal/abuse-and-molestation'
  },
  LEGAL_BYLAWS: {
    name: 'Bylaws',
    path: '/legal/bylaws'
  },
  LEGAL_DISPUTES: {
    name: 'LegalDisputesAndLawsuites',
    path: '/legal/legal-disputes-and-lawsuits'
  },
  LEGAL_PRIVACY: {
    name: 'Privacy',
    path: '/legal/privacy'
  },
  OFFICIALS_LANDING: {
    name: 'OfficialsLanding',
    path: '/officials'
  },
  OFFICIALS_RULES: {
    name: 'OfficialsRules',
    path: '/officials/playing-rules-by-sport'
  },
  OFFICIALS_REGISTRATION: {
    name: 'OfficialsRegistration',
    path: '/officials/officials-online-registration'
  },
  PROGRAMS: {
    name: 'Programs',
    path: '/programs'
  },
  PROGRAM_OVERVIEW_FASTPITCH: {
    name: 'ProgramOverviewFastpitch',
    path: 'fastpitch/fastpitchPrograms/fastpitch-program-overview'
  },
  PROGRAMS_SLOWPITCH: {
    name: 'ProgramsSlowpitch',
    path: 'slowpitch/slowpitchPrograms/senior-softball'
  },
  PROGRAMS_WS_WEEK_1: {
    name: 'WSWeek_1',
    path: 'baseball/baseballPrograms/baseball-elite-ws-week-1'
  },
  POINTS_RACE_BASEBALL: {
    name: 'PointsRaceBaseball',
    path: 'baseball/baseballPrograms/baseball-points-race'
  },
  POINTS_RACE_FASTPITCH: {
    name: 'PointsRaceFastpitch',
    path: 'fastpitch/fastpitchPrograms/fastpitch-points-race'
  },
  SAFESPORT: {
    name: 'SafeSport',
    path: '/legal/safesport'
  },
  SENIOR_SOFTBALL: {
    name: 'SeniorSoftball',
    path: 'slowpitch/slowpitchPrograms/senior-softball'
  },
  SLOWPITCH_LANDING: {
    name: 'SlowpitchLanding',
    path: '/slowpitch/landing'
  },
  SLOWPITCH_FAQs: {
    name: 'SlowpitchFAQs',
    path: '/slowpitch/faq'
  },
  SLOWPITCH_RULES: {
    name: 'SlowpitchRules',
    path: 'slowpitch/slowpitch-playing-rules'
  },
  SLOWPITCH_MAJOR_WS: {
    name: 'SlowpitchMajorWorldSeries',
    path: 'slowpitch/slowpitchPrograms/major-world-series'
  },
  SLOWPITCH_STATE_DIRECTORS: {
    name: 'SlowpitchStateDirectors',
    path: '/slowpitch/state-directors'
  },
  SLOWPITCH_FIND_DIRECTOR: {
    name: 'SlowpitchFindDirector',
    path: '/slowpitch/find-director'
  },
  SLOWPITCH_FIND_VENUE: {
    name: 'SlowpitchFindVenue',
    path: '/slowpitch/find-venue'
  },
  SPECIALTY_PROGRAMS: {
    name: 'SpecialtyPrograms',
    path: 'slowpitch/slowpitchPrograms/specialty-programs'
  },
  SPORTS: {
    name: 'Sports',
    path: '/sports'
  },
  SUPPORT: {
    name: 'SupportCenter',
    path: 'supportCenter'
  },
  TEAM_DETAILS: {
    name: 'TeamDetails',
    path: ':id'
  },
  TEAM_DETAILS_ROSTER_HISTORY: {
    name: 'TeamDetailsRosterHistory',
    path: ':id/rosterHistory'
  },
  TEAM_DETAILS_AWARDS: {
    name: 'TeamDetailsAwards',
    path: ':id/individualAwards'
  },
  TEAM_DETAILS_MANAGER_HISTORY: {
    name: 'TeamDetailsManager',
    path: ':id/managerHistory'
  },
  TEAM_DETAILS_PLAYER_STATS: {
    name: 'TeamDetailsAthleteStats',
    path: ':id/playerStats'
  },
  TEAMS: {
    name: 'Teams',
    path: '/teams'
  },
  TERMS_OF_USE: {
    name: 'TermsOfUse',
    path: '/legal/terms-of-use'
  },
  UNIVERSITY_LANDING: {
    name: 'UniversityLanding',
    path: '/university'
  },
  UNIVERSITY_DETAILS: {
    name: 'UniversityDetails',
    path: ':id'
  }
}
