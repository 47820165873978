import { defineStore } from 'pinia'

import { get, post, getLegacyEndpoint, getTSEndpoint, getMerchEndpoint } from '../../utils/apiUtils.js'

export const useCommonStore = defineStore('common', {
  state: () => ({
    availableSports: [
      {
        id: 11,
        icon: 'baseball-bat-ball',
        name: 'Baseball'
      },
      {
        id: 16,
        icon: 'baseball',
        name: 'Fastpitch'
      },
      {
        id: 17,
        icon: 'tennis-ball',
        name: 'Slowpitch'
      },
      {
        id: 12,
        icon: 'basketball',
        name: 'Basketball'
      },
      {
        id: 20,
        icon: 'basketball',
        name: 'Basketball 3on3'
      },
      {
        id: 15,
        icon: 'basketball',
        name: 'Soccer'
      }
    ],
    availableStates: [],
    availableParks: [],
    dataDisplayType: 0, // 0 = table, 1 = grid, 2 = list, 3 = map
    directors: [],
    competitiveClasses: [],
    currentTab: null,
    dataTitle: null,
    drawerOpen: false,
    hasDataError: false,
    hasZeroResults: false,
    isMobile: false,
    isLoadingData: false,
    resetDataTablePagination: false,
    showLaunchBanner: true,
    statures: [],
    module: null
  }),

  actions: {
    async fetchDataService (props) {
      const { endpoint, mutation, requestBody } = props
      try {
        const { data } = await post(endpoint, requestBody)
        mutation(data.currentPageResults)
        return true
      } catch (err) {
        throw new Error(err)
      }
    },

    async fetchFilterData (props) {
      const { endpoint, mutation, loadRowsNumber, requestBody, errorMutation } = props
      // set loadingData state true
      this.setPropValueToState({ data: true, key: 'isLoadingData' })
      this.setPropValueToState({ data: false, key: 'hasZeroResults' })
      this.setPropValueToState({ data: false, key: 'hasDataError' })
      if (errorMutation) errorMutation(null)

      mutation([])
      try {
        const { data } = await post(endpoint, requestBody)
        await mutation(data.currentPageResults)
        await loadRowsNumber(data.totalResultCount)

        if (data.currentPageResults.length === 0) {
          this.setPropValueToState({ data: true, key: 'hasZeroResults' })
        }
      } catch (err) {
        if (errorMutation) errorMutation(err)
        this.setPropValueToState({ data: true, key: 'hasDataError' })
        throw new Error(err)
      } finally {
        this.setPropValueToState({ data: false, key: 'isLoadingData' })
      }
    },

    async getDataService (data) {
      const { endpoint, mutation } = data
      try {
        const resp = await get(endpoint)
        mutation(resp.data)
        return true
      } catch (err) {
        throw new Error(err)
      }
    },

    async getLegacyEventDetails (data) {
      const { endpoint, mutation, errorMutation } = data
      try {
        this.setPropValueToState({ data: true, key: 'isLoadingData' })
        this.setPropValueToState({ data: false, key: 'hasDataError' })
        if (errorMutation) errorMutation(null)

        const { data } = await getLegacyEndpoint(endpoint)
        mutation(data)
        return true
      } catch (err) {
        throw new Error(err)
      }
    },

    async getLegacyDetails (data) {
      const { endpoint, mutation, errorMutation } = data
      try {
        this.setPropValueToState({ data: true, key: 'isLoadingData' })
        this.setPropValueToState({ data: false, key: 'hasDataError' })
        if (errorMutation) errorMutation(null)

        const { data } = await getLegacyEndpoint(endpoint)
        mutation(data)
        return true
      } catch (err) {
        throw new Error(err)
      }
    },

    async getDataTournamentSiteDetails (data) {
      const { endpoint, mutation, errorMutation } = data
      try {
        this.setPropValueToState({ data: true, key: 'isLoadingData' })
        this.setPropValueToState({ data: false, key: 'hasDataError' })
        if (errorMutation) errorMutation(null)

        const { data } = await getTSEndpoint(endpoint)
        mutation(data)
        return true
      } catch (err) {
        if (errorMutation) errorMutation(err)

        this.setPropValueToState({ data: true, key: 'hasDataError' })
        throw new Error(err)
      } finally {
        // set loadingData state false
        this.setPropValueToState({ data: false, key: 'isLoadingData' })
      }
    },

    async getEventMerchandiseDetails (data) {
      const { endpoint, mutation, errorMutation } = data
      try {
        this.setPropValueToState({ data: true, key: 'isLoadingData' })
        this.setPropValueToState({ data: false, key: 'hasDataError' })
        if (errorMutation) errorMutation(null)

        const { data } = await getMerchEndpoint(endpoint)
        mutation(data.products)
        return true
      } catch (err) {
        if (errorMutation) errorMutation(err)

        this.setPropValueToState({ data: true, key: 'hasDataError' })
        throw new Error(err)
      } finally {
        // set loadingData state false
        this.setPropValueToState({ data: false, key: 'isLoadingData' })
      }
    },

    async getDataTypeDetails (data) {
      const { endpoint, mutation, errorMutation } = data
      try {
        this.setPropValueToState({ data: true, key: 'isLoadingData' })
        this.setPropValueToState({ data: false, key: 'hasDataError' })
        if (errorMutation) errorMutation(null)

        const { data } = await get(endpoint)
        mutation(data)
        return true
      } catch (err) {
        if (errorMutation) errorMutation(err)

        this.setPropValueToState({ data: true, key: 'hasDataError' })
        throw new Error(err)
      } finally {
        // set loadingData state false
        this.setPropValueToState({ data: false, key: 'isLoadingData' })
      }
    },

    routeUserToAuth () {
      this.router.push({
        path: '/login'
      })
        .then(() => {
          location.reload()
        })
    },

    setMobileState (isMobile) {
      this.isMobile = !!isMobile
    },

    setPropValueToState (payload) {
      const { data, key } = payload
      this[key] = data
    }
  },

  getters: {
    getDataDisplayType () {
      return this.dataDisplayType
    },
    getFieldToSort () {
      return this.fieldToSort
    },

    getIsLoadingData () {
      return this.isLoadingData
    },

    getModule () {
      return this.module
    },

    getDataDisplayTypeName () {
      switch (this.dataDisplayType) {
        case 0:
          return 'table'
        case 1:
          return 'grid'
        case 2:
          return 'list'
        case 3:
          return 'map'
        case 4:
          return 'expansion'
        default:
          return 'table'
      }
    },

    isGridView () {
      return this.dataDisplayType === 1
    },

    isListView () {
      return this.dataDisplayType === 2
    },

    isMapView () {
      return this.dataDisplayType === 3
    },

    isTableView () {
      return this.dataDisplayType === 0
    },

    isExpansionView () {
      return this.dataDisplayType === 4
    }
  }
})
