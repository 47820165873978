import routeIdentifires from './routeIdentifires'

export default [
  {
    path: '/',
    component: () => import('layouts/MarketingLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: routeIdentifires.BASEBALL_LANDING.name,
        path: routeIdentifires.BASEBALL_LANDING.path,
        component: () => import('pages/SportsLanding.vue')
      },
      {
        name: routeIdentifires.FASTPITCH_LANDING.name,
        path: routeIdentifires.FASTPITCH_LANDING.path,
        component: () => import('pages/SportsLanding.vue')
      },
      {
        name: routeIdentifires.SLOWPITCH_LANDING.name,
        path: routeIdentifires.SLOWPITCH_LANDING.path,
        component: () => import('pages/SportsLanding.vue')
      }
    ]
  }
]
