import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { useAuth } from '@vueuse/firebase/useAuth'

import { useAuthStore } from 'stores/auth.js'
import { useCommonStore } from 'stores/common.js'
// import { useUserStore } from '../../stores/user.js'

let app
let authInstance
let db

// Private Functions
const _handleOnAuthStateChange = currentUser => {
  const authStore = useAuthStore()
  const commonStore = useCommonStore()
  // const userStore = useUserStore()
  const initialAuthStateSet = authStore.isAuthenticated

  authStore.setAuthState({
    isAuthenticated: currentUser !== null,
    isReady: true
  })

  // Get & bind the current user
  // if (currentUser) {
  //   userStore.getCurrentUser()
  // }

  // If the user looses authentication route
  // them to the login page
  if (!currentUser && initialAuthStateSet) {
    commonStore.routeUserToAuth()
  }
}

/**
 * Async function providing the application time to
 * wait for firebase to initialize and determine if a
 * user is authenticated or not with only a single observable
 *
 * @return {Promise} - authPromise
 */
export const ensureAuthIsInitialized = (store) => {
  if (store.$state.isReady) return true

  // Create the observer only once on init
  return new Promise((resolve, reject) => {
    // Use a promise to make sure that the router will eventually show the route after the auth is initialized.
    const unsubscribe = onAuthStateChanged(authInstance, user => {
      resolve(user)
      unsubscribe()
    }, () => {
      reject('Looks like there is a problem with the firebase service. Please try again later')
    })
  })
}

/** Convienience method to initialize firebase app
 * @param  {Object} config
 */
export const fBInit = async (config) => {
  app = initializeApp(config)
  authInstance = getAuth(app)
  db = getFirestore(app)

  // Set up initial listener for auth state change
  onAuthStateChanged(authInstance, user => {
    _handleOnAuthStateChange(user)
  })

  return app
}

export const isAuthenticated = () => {
  const { isAuthenticated } = useAuth(authInstance)
  return isAuthenticated.value
}

export { app, authInstance, db }
