import { defineStore } from 'pinia'

export const useFiltersStore = defineStore('filters', {
  state: () => ({
    athletes: {
      age: [],
      partialName: [],
      sport: [],
      state: []
    },
    events: {
      competitiveClasses: [],
      currentDivision: [],
      director: [],
      lwcSortBy: [],
      park: [],
      range: [],
      sport: [],
      statures: [],
      state: [],
      seasonYear: [],
      searchRangeStart: [],
      searchRangeEnd: [],
      zipCode: []
    },
    teams: {
      competitiveClasses: [],
      season: [],
      sport: [],
      state: []
    },
    gameCenter: {
      division: null,
      option: null,
      teamOrPoolSelection: null
    },
    teamRosterHistory: {
      selectedEvent: null
    }
  }),

  actions: {
    setFilterValue (options) {
      const { filterType, isPushArray, prop, value } = options
      isPushArray
        ? this[filterType][prop].push(value)
        : this[filterType][prop] = value
    },
    resetFilters (filterType) {
      const filter = this[filterType]
      const keys = Object.keys(filter)

      keys.forEach(filterProp => {
        filter[filterProp] = []
      })
    }
  },
  getters: {
    getCompetitiveClasses: state => state.events.competitiveClasses,

    getFilters: state => {
      return (filterType) => state[filterType]
    }
  }
  // persist: true
})
