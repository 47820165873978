import routeIdentifires from './routeIdentifires'

export default [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: routeIdentifires.BASEBALL_FAQs.name,
        path: routeIdentifires.BASEBALL_FAQs.path,
        component: () => import('pages/FrequentlyAskedQuestions.vue')
      },
      {
        name: routeIdentifires.BASEBALL_STATE_DIRECTORS.name,
        path: routeIdentifires.BASEBALL_STATE_DIRECTORS.path,
        component: () => import('pages/StateDirectors.vue')
      },
      {
        name: routeIdentifires.BASEBALL_FIND_DIRECTOR.name,
        path: routeIdentifires.BASEBALL_FIND_DIRECTOR.path,
        component: () => import('pages/FindDirector.vue')
      },
      {
        name: routeIdentifires.BASEBALL_FIND_VENUE.name,
        path: routeIdentifires.BASEBALL_FIND_VENUE.path,
        component: () => import('pages/FindVenue.vue')
      },
      {
        name: routeIdentifires.CHALLENGE_CUP.name,
        path: routeIdentifires.CHALLENGE_CUP.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.ELLITE_WORLD_SERIES.name,
        path: routeIdentifires.ELLITE_WORLD_SERIES.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.EQUIPMENT.name,
        path: routeIdentifires.EQUIPMENT.path,
        component: () => import('pages/Equipment.vue')
      },
      {
        name: routeIdentifires.FASTPITCH_FAQs.name,
        path: routeIdentifires.FASTPITCH_FAQs.path,
        component: () => import('pages/FrequentlyAskedQuestions.vue')
      },
      {
        name: routeIdentifires.FASTPITCH_STATE_DIRECTORS.name,
        path: routeIdentifires.FASTPITCH_STATE_DIRECTORS.path,
        component: () => import('pages/StateDirectors.vue')
      },
      {
        name: routeIdentifires.FASTPITCH_FIND_DIRECTOR.name,
        path: routeIdentifires.FASTPITCH_FIND_DIRECTOR.path,
        component: () => import('pages/FindDirector.vue')
      },
      {
        name: routeIdentifires.FASTPITCH_FIND_VENUE.name,
        path: routeIdentifires.FASTPITCH_FIND_VENUE.path,
        component: () => import('pages/FindVenue.vue')
      },
      {
        name: routeIdentifires.HALL_OF_FAME.name,
        path: routeIdentifires.HALL_OF_FAME.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.LEGAL_ABUSE.name,
        path: routeIdentifires.LEGAL_ABUSE.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.LEGAL_BYLAWS.name,
        path: routeIdentifires.LEGAL_BYLAWS.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.OFFICIALS_LANDING.name,
        path: routeIdentifires.OFFICIALS_LANDING.path,
        component: () => import('pages/OfficialsLanding.vue')
      },
      {
        name: routeIdentifires.OFFICIALS_RULES.name,
        path: routeIdentifires.OFFICIALS_RULES.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.OFFICIALS_REGISTRATION.name,
        path: routeIdentifires.OFFICIALS_REGISTRATION.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.LEGAL_PRIVACY.name,
        path: routeIdentifires.LEGAL_PRIVACY.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        path: routeIdentifires.LEGAL_DISPUTES.path,
        name: routeIdentifires.LEGAL_DISPUTES.name,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.PROGRAM_OVERVIEW_FASTPITCH.name,
        path: routeIdentifires.PROGRAM_OVERVIEW_FASTPITCH.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.PROGRAMS_SLOWPITCH.name,
        path: routeIdentifires.PROGRAMS_SLOWPITCH.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.PROGRAMS_WS_WEEK_1.name,
        path: routeIdentifires.PROGRAMS_WS_WEEK_1.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        path: routeIdentifires.POINTS_RACE_BASEBALL.path,
        name: routeIdentifires.POINTS_RACE_BASEBALL.name,
        component: () => import('pages/StaticContent.vue')
      },
      {
        path: routeIdentifires.POINTS_RACE_FASTPITCH.path,
        name: routeIdentifires.POINTS_RACE_FASTPITCH.name,
        component: () => import('pages/StaticContent.vue')
      },
      {
        path: routeIdentifires.SENIOR_SOFTBALL.path,
        name: routeIdentifires.SENIOR_SOFTBALL.name,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.SAFESPORT.name,
        path: routeIdentifires.SAFESPORT.path,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.SLOWPITCH_FAQs.name,
        path: routeIdentifires.SLOWPITCH_FAQs.path,
        component: () => import('pages/FrequentlyAskedQuestions.vue')
      },
      {
        path: routeIdentifires.SLOWPITCH_MAJOR_WS.path,
        name: routeIdentifires.SLOWPITCH_MAJOR_WS.name,
        component: () => import('pages/StaticContent.vue')
      },
      {
        path: routeIdentifires.SLOWPITCH_RULES.path,
        name: routeIdentifires.SLOWPITCH_RULES.name,
        component: () => import('pages/StaticContent.vue')
      },
      {
        name: routeIdentifires.SLOWPITCH_STATE_DIRECTORS.name,
        path: routeIdentifires.SLOWPITCH_STATE_DIRECTORS.path,
        component: () => import('pages/StateDirectors.vue')
      },
      {
        name: routeIdentifires.SLOWPITCH_FIND_DIRECTOR.name,
        path: routeIdentifires.SLOWPITCH_FIND_DIRECTOR.path,
        component: () => import('pages/FindDirector.vue')
      },
      {
        name: routeIdentifires.SLOWPITCH_FIND_VENUE.name,
        path: routeIdentifires.SLOWPITCH_FIND_VENUE.path,
        component: () => import('pages/FindVenue.vue')
      },
      {
        path: routeIdentifires.SPECIALTY_PROGRAMS.path,
        name: routeIdentifires.SPECIALTY_PROGRAMS.name,
        component: () => import('pages/StaticContent.vue')
      },
      {
        path: routeIdentifires.TERMS_OF_USE.path,
        name: routeIdentifires.TERMS_OF_USE.name,
        component: () => import('pages/StaticContent.vue')
      }
    ]
  }
]
