import routeIdentifires from './routeIdentifires'

export default [
  {
    path: '/test-cms',
    component: () => import('layouts/MainLayout.vue'),
    meta: { layoutType: 'mainLayout' },
    children: [
      {
        path: '',
        name: 'TestCMS',
        component: () => import('src/pages/TestCms.vue')
      }
    ]
  },
  {
    path: '/brackets',
    component: () => import('layouts/MainLayout.vue'),
    meta: { layoutType: 'mainLayout' },
    children: [
      {
        path: '',
        name: 'brackets',
        component: () => import('src/pages/BracketsPage.vue')
      }
    ]
  },
  {
    path: '/home',
    redirect: { name: routeIdentifires.EVENTS.name }
  },
  {
    path: '/',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: routeIdentifires.LOGIN.name
        }
      },
      {
        name: routeIdentifires.LOGIN.name,
        path: routeIdentifires.LOGIN.path,
        props: true,
        component: () => import('pages/authentication/AuthPage.vue')
      },
      {
        name: routeIdentifires.FORGOT_PASSWORD.name,
        path: routeIdentifires.FORGOT_PASSWORD.path,
        component: () => import('pages/authentication/ForgotPassword.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        redirect: {
          name: routeIdentifires.EVENTS.name
        }
      },
      {
        path: routeIdentifires.CONTACT.path,
        name: routeIdentifires.CONTACT.name,
        component: () => import('src/pages/ContactPage.vue')
      },
      {
        path: routeIdentifires.FAQs.path,
        name: routeIdentifires.FAQs.name,
        component: () => import('pages/FrequentlyAskedQuestions.vue')
      }
    ]
  },
  {
    path: routeIdentifires.ATHLETES.path,
    component: () => import('layouts/LandingLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: routeIdentifires.ATHLETES.name,
        component: () => import('pages/athletes/AthletesLanding.vue'),
        props: true
      }
    ]
  },
  {
    path: routeIdentifires.ATHLETES.path,
    component: () => import('layouts/DetailsLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: routeIdentifires.ATHLETE_DETAILS.path,
        name: routeIdentifires.ATHLETE_DETAILS.name,
        component: () => import('pages/athletes/AthleteDetails.vue')
      },
      {
        path: routeIdentifires.ATHLETE_DETAILS_STATS.path,
        name: routeIdentifires.ATHLETE_DETAILS_STATS.name,
        component: () => import('pages/athletes/AthleteDetails.vue')
      },
      {
        path: routeIdentifires.ATHLETE_DETAILS_EVENTS.path,
        name: routeIdentifires.ATHLETE_DETAILS_EVENTS.name,
        component: () => import('pages/athletes/AthleteDetails.vue'),
        props: route => ({ query: route.query.individualEventsTab })
      },
      {
        path: routeIdentifires.ATHLETE_DETAILS_GAMES.path,
        name: routeIdentifires.ATHLETE_DETAILS_GAMES.name,
        component: () => import('pages/athletes/AthleteDetails.vue')
      },
      {
        path: routeIdentifires.ATHLETE_DETAILS_AWARDS.path,
        name: routeIdentifires.ATHLETE_DETAILS_AWARDS.name,
        component: () => import('pages/athletes/AthleteDetails.vue')
      }
      // {
      //   path: routeIdentifires.AGE_CALCULATOR.path,
      //   name: routeIdentifires.AGE_CALCULATOR.name,
      //   component: () => import('pages/athletes/AthleteAgeCalculator.vue')
      // }
    ]
  },
  {
    path: routeIdentifires.EVENTS.path,
    component: () => import('layouts/LandingLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: routeIdentifires.EVENTS.name,
        component: () => import('pages/events/EventsLanding.vue'),
        props: true
      }
    ]
  },
  {
    path: routeIdentifires.EVENTS.path,
    component: () => import('layouts/DetailsLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: routeIdentifires.EVENT_DETAILS.name,
        path: routeIdentifires.EVENT_DETAILS.path,
        component: () => import('pages/events/EventDetails.vue'),
        props: true
      },
      {
        name: routeIdentifires.EVENT_DETAILS_GAME_CENTER.name,
        path: routeIdentifires.EVENT_DETAILS_GAME_CENTER.path,
        component: () => import('pages/events/EventDetails.vue'),
        props: route => ({ query: route.query.gameCenterTab })
      },
      {
        name: routeIdentifires.EVENT_DETAILS_WHOS_COMING.name,
        path: routeIdentifires.EVENT_DETAILS_WHOS_COMING.path,
        component: () => import('pages/events/EventDetails.vue'),
        props: true
      },
      {
        name: routeIdentifires.EVENT_DETAILS_VENUES.name,
        path: routeIdentifires.EVENT_DETAILS_VENUES.path,
        component: () => import('pages/events/EventDetails.vue'),
        props: true
      },
      {
        name: routeIdentifires.EVENT_DETAILS_MERCHANDISE.name,
        path: routeIdentifires.EVENT_DETAILS_MERCHANDISE.path,
        component: () => import('pages/events/EventDetails.vue'),
        props: true
      }
    ]
  },
  {
    path: routeIdentifires.TEAMS.path,
    component: () => import('layouts/LandingLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: routeIdentifires.TEAMS.name,
        path: '',
        component: () => import('src/pages/teams/TeamsLanding.vue')
      }
    ]
  },
  {
    path: routeIdentifires.TEAMS.path,
    component: () => import('layouts/DetailsLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: routeIdentifires.TEAM_DETAILS.name,
        path: routeIdentifires.TEAM_DETAILS.path,
        component: () => import('src/pages/teams/TeamDetails.vue')
      },
      {
        name: routeIdentifires.TEAM_DETAILS_ROSTER_HISTORY.name,
        path: routeIdentifires.TEAM_DETAILS_ROSTER_HISTORY.path,
        component: () => import('pages/teams/TeamDetails.vue'),
        props: true
      },
      {
        name: routeIdentifires.TEAM_DETAILS_AWARDS.name,
        path: routeIdentifires.TEAM_DETAILS_AWARDS.path,
        component: () => import('pages/teams/TeamDetails.vue'),
        props: true
      },
      {
        name: routeIdentifires.TEAM_DETAILS_MANAGER_HISTORY.name,
        path: routeIdentifires.TEAM_DETAILS_MANAGER_HISTORY.path,
        component: () => import('pages/teams/TeamDetails.vue'),
        props: true
      },
      {
        name: routeIdentifires.TEAM_DETAILS_PLAYER_STATS.name,
        path: routeIdentifires.TEAM_DETAILS_PLAYER_STATS.path,
        component: () => import('pages/teams/TeamDetails.vue'),
        props: true
      }
    ]
  },
  {
    path: routeIdentifires.UNIVERSITY_LANDING.path,
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: routeIdentifires.UNIVERSITY_LANDING.name,
        component: () => import('pages/usssaUniversity/UniversityLanding.vue')
      },
      {
        name: routeIdentifires.UNIVERSITY_DETAILS.name,
        path: routeIdentifires.UNIVERSITY_DETAILS.path,
        component: () => import('pages/usssaUniversity/UniversityDetails.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404Page.vue')
  }
]
