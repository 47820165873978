import firebaseServices from 'services/gcp'
import { useAuthStore } from 'stores/auth'
import { Notify } from 'quasar'

export default async (to, from, next) => {
  const authStore = useAuthStore()
  const {
    ensureAuthIsInitialized,
    isAuthenticated
  } = firebaseServices

  try {
    await ensureAuthIsInitialized(authStore)
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (isAuthenticated()) {
        next()
      } else {
        next('/login')
      }
    } else {
      next()
    }
  } catch (err) {
    Notify.create({
      type: 'webapp_error',
      message: `There is a routing problem: ${err}`
    })
  }
}
