// import { useMeta } from 'quasar'
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import common from './common'
import staticContent from './static'
import sports from './sports'
import { useFiltersStore } from '../stores/filters'
import routeGuard from './routeGuard'
import diDNA from 'composables/diDNA.js'

const { refreshAdUnits } = diDNA()

const routes = [
  ...common,
  ...sports,
  ...staticContent
]

/*
* If not building with SSR mode, you can
* directly export the Router instantiation;
*
* The function below can be async too; either use
* async/await or return a Promise which resolves
* with the Router instance.
*/
let router

export default route(function ({ store }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    // scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
    linkActiveClass: 'active'
  })

  const handleAdRefresh = () => {
    refreshAdUnits()
  }

  const handleFilterPersistence = (to, from) => {
    const $filters = useFiltersStore()
    const perisistedRoutes = ['events', 'teams', 'athletes']
    const fromRoute = from.path.split('/')[1]
    const toRoute = to.path.split('/')[1]

    if (toRoute !== fromRoute && perisistedRoutes.some(persitedRoute => fromRoute.includes(persitedRoute))) {
      $filters.resetFilters(fromRoute)
    }
  }

  // Handle redirects regarding legacy usssa.com with teamID in the url
  const redirectToTeamsIfHaveTeamIdInQuery = (to, from, next) => {
    if (to.query.teamID) return next(`/teams/${to.query.teamID}`)
  }

  // Handle redirects regarding legacy usssa.com with divisionID in the url
  const redirectToDivisions = (to, from, next) => {
    const params = to.fullPath.split('/')[2]?.split('&')
    const divisionIdProp = params?.filter(p => p.match('divisionID'))
    const hasDivisionIdProp = divisionIdProp?.length > 0
    if (hasDivisionIdProp) {
      const divisionId = divisionIdProp[0].split('=')[1]
      return next(`/events/${params[0]}/divisions/${divisionId}`)
    }
  }

  // const setMetaDescription = () => {
  //   const metaData = {
  //     meta: {
  //       description: {
  //         name: 'description',
  //         content: 'A place to play for all ages and skill levels. USSSA empowers world-class events for athletes, parents, and fans.'
  //       }
  //     }
  //   }
  //   useMeta(metaData)
  // }

  Router.beforeEach(async (to, from, next) => {
    // setMetaDescription()
    redirectToTeamsIfHaveTeamIdInQuery(to, from, next)
    redirectToDivisions(to, from, next)
    handleFilterPersistence(to, from)
    handleAdRefresh()
    await routeGuard(to, from, next)
  })

  router = Router

  return Router
})

export { router }
