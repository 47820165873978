import { boot } from 'quasar/wrappers'
import axios from 'axios'

import apiAuthIntercepter from '../../utils/apiAuthIntercepter'

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

// SERVICE ENDPOINTS
const usssaLegacyApi = axios.create({ baseURL: process.env.QENV.LEGACY_API })
const usssaServicesApi = axios.create({ baseURL: process.env.QENV.EPNG_EVENT_SERVICES })
const cloudServicesApi = axios
  .create({
    baseURL: process.env.QENV.USE_EMULATORS === 'local'
      ? 'http://localhost:5001/usssa-ssr-multisite-dev/us-central1/cloudServices'
      : process.env.QENV.CLOUD_SERVICES
  })

const merchandiseApi = axios.create({
  baseURL: 'https://api.tournamentgear.com/event-products',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '500aca9b-664a-41b6-bca7-777e51a9402f'
  }
})

const tournamentSitesApi = axios
  .create({
    baseURL: 'https://api.tournamentgear.com/event-details',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': '500aca9b-664a-41b6-bca7-777e51a9402f'
    }
  })

const dotCMSServicesApi = axios.create({ baseURL: process.env.QENV.DOTCMS_SERVICES })
dotCMSServicesApi.interceptors.request.use(function (config) {
  // Do something before request is sent

  return apiAuthIntercepter(config)
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

export default boot(({ app, store }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$usssaServicesApi = usssaServicesApi
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
})

export { cloudServicesApi, dotCMSServicesApi, usssaLegacyApi, tournamentSitesApi, usssaServicesApi, merchandiseApi }
