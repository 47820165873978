import { authInstance } from './base'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth '

/**
 * https://firebase.google.com/docs/reference/js/auth.md#createuserwithemailandpassword
 *
 * @param {String} auth - Current Auth instance
 * @param {String} email - A Valid email
 * @param {String} password - Password
 *
 * @return {Promise} UserCredentials
 */

export const createUserWithEmail = (email, password) => {
  return createUserWithEmailAndPassword(authInstance, email, password)
}

/**
 * remove firebase auth token
 */
export const logoutUser = () => {
  return authInstance.signOut()
}

// /**
//  * @param {String} email - A Valid email
//  * @param {String} password - Password
//  *
//  * @return {Promist} UserCredentials
//  */
export const loginWithEmail = (email, password) => {
  return signInWithEmailAndPassword(authInstance, email, password)
}

export const resetPassword = (email) => {
  return sendPasswordResetEmail(authInstance, email)
}
