import { defineStore } from 'pinia'
import firebaseServices from '../services/gcp'
import { Notify } from 'quasar'
// import { useUserStore } from './user'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    isReady: false,
    isSignedIn: false,
    isAdmin: false
  }),
  actions: {
    async forgotPassword (email) {
      try {
        await firebaseServices.resetPassword(email)
      } catch (err) {
        Notify.create({
          type: 'webapp_error',
          message: `An error as occured [forgotPassword]: ${err}`
        })
      }
    },

    async loginUser (payload) {
      const { email, password } = payload
      return await firebaseServices.loginWithEmail(email, password)
    },

    async logoutUser (id) {
      // const userStore = useUserStore()
      try {
        await firebaseServices.logoutUser()
        // userStore.unbindCurrentUser(null)
      } catch (err) {
        Notify.create({
          type: 'webapp_error',
          message: `An error occured [logoutUser]: ${err}`
        })
        return err
      }
    },

    async sendEmailVerification () {
      try {
        await firebaseServices.sendEmailVerification()
        return true
      } catch (err) {
        Notify.create({
          type: 'webapp_error',
          message: `An error as occured [sendEmailVerification]: ${err}`
        })
        return (false)
      }
    },

    setAuthState (data) {
      this.isAuthenticated = data.isAuthenticated
      this.isReady = data.isReady
    }
  }
})
